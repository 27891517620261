(function () {
    'use strict';

    /**
     * The uppercaseFirst Filter allows the display of a string to begin with an uppercase letter
     *
     * @memberof ihh-filters
     * @ngdoc filter
     * @name unlimitedDisplay
     * @description
     *  Returns an alternative display of a number to be modified if it exceeds one million.
     */
    angular
        .module('ihh-filters')
        .filter('uppercaseFirst', uppercaseFirst);

    function uppercaseFirst() {
        /**
         * @func uppercaseFirstFilter
         * @memberOf uppercaseFirst
         * @description
         *  Create the return function that allows the display of a string to begin with an uppercase letter
         * @param {String} The string to modify
         */
        return function (input) {
            var firstChar;
            var returnValue = input;

            if (angular.isString(input)) {
                firstChar = input.charAt(0);
                returnValue = firstChar.toUpperCase() + input.slice(1, input.length);
            }

            return returnValue;
        };
    }
})();

