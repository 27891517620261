(function () {
    'use strict';

    /**
     * @ngdoc overview
     * @name ihh-common-web
     * @description
     *  . Define the dependencies of the ihh-common-web module here
     * @requires ihh-common-web-tpls
     * @requires ngResource
     * @requires ihh-bootstrap
     * @requires ihh-filters
     */
    angular
        .module('ihh-common-web', [
            'ihh-common-web-tpls',
            'ngResource',
            'ihh-bootstrap',
            'ihh-filters'
        ]);
})();

(function() {
    'use strict';

    /**
     * @ngdoc constant
     * @name _
     * @memberof ihh-common-web
     * @description
     *  . Window alias for lodash
     */
    angular
        .module('ihh-common-web')
        .constant('_', window._)
        .constant('d3', window.d3);
})();

(function() {
    'use strict';

    /**
     * @ngdoc overview
     * @name ihh-filters
     * @description
     *  . Common filters
     */
    angular
        .module('ihh-filters', []);
})();
