(function () {
    'use strict';

    angular
        .module('ihh-common-web')
        .directive('scrollOnClick', scrollOnClick);

    scrollOnClick.$inject = ['$window', '$timeout'];

    /**
     * @memberOf ihh-common-web
     * @ngdoc directive
     * @name scrollOnClick
     * @description
     *  . This directive is used to scroll to an item when it is clicked.
     */
    function scrollOnClick($window, $timeout) {
        /**
         * Initialize scroll on click event
         * @memberOf scrollOnClick
         * @param {service} scope the scope of this element
         * @param {service} $elm element that this directive is assigned to
         */
        var linkFunc = function (scope, $elm) {
            $elm.on('click', function() {
                $timeout(function() {
                    $window.scrollTo(0, $elm.offset().top);
                });
            });
        };

        return {
            restrict: 'A',
            link: linkFunc
        };
    }
})();