(function () {
    'use strict';

    angular
        .module('ihh-common-web')
        .constant('ProfileConstants', {
            pollingFrequency: {
                seconds: '300',
                milliseconds: '300000'
            },
            observers: {
                refresh: 'ProfileConstants.observers.refresh',
                alertsRetrieved: 'ProfileConstants.observers.alertsRetrieved'
            }
        });
})();