(function() {
    'use strict';

    angular
        .module('ihh-common-web')
        .constant('Frequencies', [
            {
                label: 'Once a Day',
                property: '1Per1Day',
                value: 1 / 1 //(1/1)
            }, {
                label: 'Two Times a Day',
                property: '2Per1Day',
                value: 2 / 1 //(2/1)
            }, {
                label: 'Three Times a Day',
                property: '3Per1Day',
                value: 3 / 1 //(3/1)
            }, {
                label: 'Five Times a Day',
                property: '5Per1Day',
                value: 5 / 1 //(5/1)
            }, {
                label: 'Once a Week',
                property: '1Per7Days',
                value: 1 / 7 //(1/7)
            }, {
                label: 'Two Times a Week',
                property: '2Per7Days',
                value: 2 / 7 //(2/7)
            }, {
                label: 'Three Times a Week',
                property: '3Per7Days',
                value: 3 / 7 //(3/7)
            }, {
                label: 'Every Other Day',
                property: '1Per2Days',
                value: 1 / 2 //(1/2)
            }
        ]);
})();

(function() {
    'use strict';

    angular
        .module('ihh-common-web')
        .constant('AdditionalPlaceholderClasses', 'list-group-item btn btn-default ihh-item');
})();