(function () {
    'use strict';

    angular.module('ihh-common-web')
        .constant('CommonPricingPlanConstants', {
            label: {
                pricePerTherapist: 'PricePerTherapist',
                price: 'Price'
            },
            paymentPeriod: {
                month: {
                    key: 'monthly',
                    label: 'Monthly',
                    labelLowercase: 'monthly',
                    paid: 'Monthly',
                    termPeriod: 'year',
                    paymentPeriod: 'month',
                    paymentPeriodLabel: 'Monthly',
                    priceLabel: 'Monthly'
                },
                year: {
                    key: 'annual',
                    label: 'Annually',
                    labelLowercase: 'annually',
                    paid: 'Annually',
                    termPeriod: 'year',
                    paymentPeriodLabel: 'Annually',
                    paymentPeriod: 'year',
                    priceLabel: 'Annual'
                }
            }
        });
})();