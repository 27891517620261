(function () {
    'use strict';

    angular
        .module('ihh-common-web')
        .service('ClinicPricingPlanUtilities', ClinicPricingPlanUtilities);

    ClinicPricingPlanUtilities.$inject = ['Utilities', 'CommonPricingPlanConstants'];

    function ClinicPricingPlanUtilities(Utilities, CommonPricingPlanConstants) {
        function getRenewalChanges(subscription) {
            var plan = subscription.plan;
            if (subscription.term && subscription.renewalTerm) {
                var renewalPlan = subscription.renewalPlan;
                var hasRenewalChanges = false;
                var hasChangedPlan = plan.id !== renewalPlan.id;
                var hasDecreasedAdditionalTherapists = subscription.additionalTherapists > subscription.renewalAdditionalTherapists;
                var hasChangedTerm = subscription.term.subscriptionTermName !== subscription.renewalTerm.subscriptionTermName;

                hasRenewalChanges = hasChangedPlan || hasDecreasedAdditionalTherapists || hasChangedTerm;

                return {
                    renewalChanges: hasRenewalChanges,
                    changedPlan: hasChangedPlan,
                    decreasedAdditionalTherapists: hasDecreasedAdditionalTherapists,
                    changedTerm: hasChangedTerm
                };
            }
            else {
                return {
                    renewalChanges: false,
                    changedPlan: false,
                    decreasedAdditionalTherapists: false,
                    changedTerm: false
                };
            }
        }

        function updateCalculatedSubscriptionFields(subscription) {
            var term = subscription.term;
            var renewalTerm = subscription.renewalTerm;
            var planChanges = getRenewalChanges(subscription);

            if (Utilities.isDefinedAndNotNull(term) && !angular.isString(term) && Utilities.isDefinedAndNotNull(term.key)) {
                term = term.key;
            }

            if (Utilities.isDefinedAndNotNull(renewalTerm) && !angular.isString(renewalTerm) && Utilities.isDefinedAndNotNull(renewalTerm.key)) {
                renewalTerm = renewalTerm.key;
            }

            if (term) {
                subscription.price = subscription.productPrice;
                subscription.planCost = subscription.price + subscription.additionalTherapistsPrice;
            }
            else {
                subscription.price = 0;
            }

            if (renewalTerm) {
                if (subscription.renewalTermPeriod && subscription.renewalPaymentPeriod) {
                    subscription.renewalAdditionalTherapistsPrice = subscription.renewalPlan[CommonPricingPlanConstants.termPeriod[subscription.renewalTermPeriod].paymentPeriod[subscription.renewalPaymentPeriod].key + CommonPricingPlanConstants.label.pricePerTherapist];
                    subscription.renewalProductPrice = subscription.renewalPlan[CommonPricingPlanConstants.termPeriod[subscription.renewalTermPeriod].paymentPeriod[subscription.renewalPaymentPeriod].key + CommonPricingPlanConstants.label.price];
                }

                subscription.renewalPrice = subscription.renewalProductPrice;
                subscription.renewalAdditionalTherapistsCost = subscription.renewalAdditionalTherapistsPrice * subscription.renewalAdditionalTherapists;
                subscription.renewalPlanCost = subscription.renewalPrice + subscription.renewalAdditionalTherapistsCost;
            }
            else {
                subscription.renewalPrice = 0;
            }

            subscription.renewalChanges = planChanges.renewalChanges;
            subscription.changedPlan = planChanges.changedPlan;
            subscription.decreasedAdditionalTherapists = planChanges.decreasedAdditionalTherapists;
            subscription.changedTerm = planChanges.changedTerm;

            return subscription;
        }

        function removeCalculatedSubscriptionFields(subscription) {
            delete subscription.price;
            delete subscription.additionalTherapistsCost;
            delete subscription.planCost;
            delete subscription.renewalPrice;
            delete subscription.renewalPlanCost;
            delete subscription.renewalChanges;
            delete subscription.changedPlan;
            delete subscription.decreasedAdditionalTherapists;
            delete subscription.changedTerm;
        }

        return {
            getRenewalChanges: getRenewalChanges,
            updateCalculatedSubscriptionFields: updateCalculatedSubscriptionFields,
            removeCalculatedSubscriptionFields: removeCalculatedSubscriptionFields
        };
    }
})();

