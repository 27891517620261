(function () {
    'use strict';

    angular
        .module('ihh-common-web')
        .constant('CommonResourceMaterialsConstants', {
            type: {
                webPage: {
                    label: 'Webpage',
                    type: 'url'
                },
                video: {
                    label: 'Video'
                },
                pdf: {
                    label: 'PDF'
                }
            }
        });
})();
