(function () {
    'use strict';

    angular
        .module('ihh-common-web')
        .factory('ResourceMaterialsFactory', ResourceMaterialsFactory);

    ResourceMaterialsFactory.$inject = ['CommonResourceMaterialsConstants'];

    function ResourceMaterialsFactory (CommonResourceMaterialsConstants) {
        var service = {};

        /**
         * This function determines a resource media type.
         * @param {Object} resource
         * @returns {String} mediaType - the type of media
         */
        service.getType = function (resource) {
            var type = '';

            if (resource) {
                if (angular.isString(resource.url)) {
                    type = CommonResourceMaterialsConstants.type.webPage.type;
                }
                else if (resource.mediaType) {
                    type = resource.mediaType;
                }
            }

            return type;
        };

        service.getTypeLabel = function (resource) {
            var label = '';

            if (resource) {
                if (angular.isString(resource.url)) {
                    label = CommonResourceMaterialsConstants.type.webPage.label;
                }
                else if (resource.mediaType) {
                    if (resource.mediaType === 'application/pdf') {
                        label = CommonResourceMaterialsConstants.type.pdf.label;
                    }
                    else {
                        label = CommonResourceMaterialsConstants.type.video.label;
                    }
                }
            }

            return label;
        };

        return service;
    }
})();