(function () {
    'use strict';

    angular
        .module('ihh-common-web')
        .service('AppConfigSvc', AppConfigSvc);

    function AppConfigSvc() {
        var self = this;
        var PATIENT_APP_NAME = 'patient';
        var CLINIC_APP_NAME = 'therapist';
        var OPERATIONS_APP_NAME = 'admin';
        var appConfig;

        self.setAppConfig = function(config) {
            appConfig = config;
        };

        self.getAppConfig = function() {
            return appConfig;
        };

        self.getApp = function() {
            return appConfig.app;
        };

        self.isOpsApp = function() {
            return self.getApp() === OPERATIONS_APP_NAME;
        };

        self.isPatientApp = function() {
            return self.getApp() === PATIENT_APP_NAME;
        };

        self.isClinicApp = function() {
            return self.getApp() === CLINIC_APP_NAME;
        };

        self.getTerms = function() {
            return appConfig.terms;
        };

        self.getBuildVersion = function() {
            return appConfig.buildVersion;
        };

        self.getTitle = function() {
            return appConfig.title;
        };

        self.getLoginParams = function() {
            return appConfig.loginParams;
        };

        self.getApiPath = function() {
            return appConfig.restHost + appConfig.restPath;
        };

        self.getQueryLimit = function() {
            return appConfig.queryLimit;
        };

        self.getExerciseLinkRoute = function () {
            return appConfig.exerciseLinkRoute;
        };

        self.getMinWidth = function () {
            return appConfig.minWidth || 0;
        };

        return self;
    }
})();