(function () {
    'use strict';

    angular
        .module('ihh-common-web')
        .constant('permissionConstants', {
            /**
             * The permission to do "special" things. Big mojo.
             */
            SPECIAL_OPERATIONS: "special.operations",

            /**
             * The permission to create tags.
             */
            CREATE_TAG: "create.tag",

            /**
             * The permission to read tags.
             */
            READ_TAG: "read.tag",

            /**
             * The permission to update tags.
             */
            UPDATE_TAG: "update.tag",

            /**
             * The permission to delete tags.
             */
            DELETE_TAG: "delete.tag",

            /**
             * The permission to create diagnosis codes.
             */
            CREATE_DIAGNOSIS_CODE: "create.diagnosis.code",

            /**
             * The permission to read diagnosis codes.
             */
            READ_DIAGNOSIS_CODE: "read.diagnosis.code",

            /**
             * The permission to update diagnosis codes.
             */
            UPDATE_DIAGNOSIS_CODE: "update.diagnosis.code",

            /**
             * The permission to delete diagnosis codes.
             */
            DELETE_DIAGNOSIS_CODE: "delete.diagnosis.code",

            /**
             * The permission to create resistance items.
             */
            CREATE_RESISTANCE_ITEM: "create.resistance.item",

            /**
             * The permission to read resistance items.
             */
            READ_RESISTANCE_ITEM: "read.resistance.item",

            /**
             * The permission to update resistance items.
             */
            UPDATE_RESISTANCE_ITEM: "update.resistance.item",

            /**
             * The permission to delete resistance items.
             */
            DELETE_RESISTANCE_ITEM: "delete.resistance.item",

            /**
             * The permission to create configurations.
             */
            CREATE_CONFIG: "create.configuration",

            /**
             * The permission to read configurations.
             */
            READ_CONFIG: "read.configuration",

            /**
             * The permission to update configurations.
             */
            UPDATE_CONFIG: "update.configuration",

            /**
             * The permission to delete configurations.
             */
            DELETE_CONFIG: "delete.configuration",

            /**
             * The permission to create clinic groups.
             */
            CREATE_CLINIC_GROUP: "create.clinic-group",

            /**
             * The permission to read clinic groups.
             */
            READ_CLINIC_GROUP: "read.clinic-group",

            /**
             * The permission to update clinic groups.
             */
            UPDATE_CLINIC_GROUP: "update.clinic-group",

            /**
             * The permission to delete clinic groups.
             */
            DELETE_CLINIC_GROUP: "delete.clinic-group",

            /**
             * The permission to create branded apps.
             */
            CREATE_BRANDED_APP: "create.branded-app",

            /**
             * The permission to read branded apps.
             */
            READ_BRANDED_APP: "read.branded-app",

            /**
             * The permission to update branded apps.
             */
            UPDATE_BRANDED_APP: "update.branded-app",

            /**
             * The permission to delete branded apps.
             */
            DELETE_BRANDED_APP: "delete.branded-app",

            /**
             * The permission to create clinics.
             */
            CREATE_CLINIC: "create.clinic",

            /**
             * The permission to read clinics.
             */
            READ_CLINIC: "read.clinic",

            /**
             * The permission to update clinics.
             */
            UPDATE_CLINIC: "update.clinic",

            /**
             * The permission to delete clinics.
             */
            DELETE_CLINIC: "delete.clinic",

            /**
             * The permission to create exercises.
             */
            CREATE_EXERCISE: "create.exercise",

            /**
             * The permission to read exercises.
             */
            READ_EXERCISE: "read.exercise",

            /**
             * The permission to update exercises.
             */
            UPDATE_EXERCISE: "update.exercise",

            /**
             * The permission to delete exercises.
             */
            DELETE_EXERCISE: "delete.exercise",

            /**
             * The permission to create resource materials.
             */
            CREATE_RESOURCE_MATERIAL: "create.resource.material",

            /**
             * The permission to read resource materials.
             */
            READ_RESOURCE_MATERIAL: "read.resource.material",

            /**
             * The permission to update resource materials.
             */
            UPDATE_RESOURCE_MATERIAL: "update.resource.material",

            /**
             * The permission to delete resource materials.
             */
            DELETE_RESOURCE_MATERIAL: "delete.resource.material",

            /**
             * The permission to create templates.
             */
            CREATE_EXERCISE_TEMPLATE: "create.exercise.template",

            /**
             * The permission to read templates.
             */
            READ_EXERCISE_TEMPLATE: "read.exercise.template",

            /**
             * The permission to update templates.
             */
            UPDATE_EXERCISE_TEMPLATE: "update.exercise.template",

            /**
             * The permission to delete templates.
             */
            DELETE_EXERCISE_TEMPLATE: "delete..template",

            /**
             * The permission to create protocols.
             */
            CREATE_PROTOCOL: "create.protocol",

            /**
             * The permission to read protocols.
             */
            READ_PROTOCOL: "read.protocol",

            /**
             * The permission to update protocols.
             */
            UPDATE_PROTOCOL: "update.protocol",

            /**
             * The permission to delete protocols.
             */
            DELETE_PROTOCOL: "delete.protocol",

            /**
             * The permission to create clinic/staff.
             */
            CREATE_CLINIC_STAFF: "create.clinic.staff",

            /**
             * The permission to read clinic/staff.
             */
            READ_CLINIC_STAFF: "read.clinic.staff",

            /**
             * The permission to update clinic/staff.
             */
            UPDATE_CLINIC_STAFF: "update.clinic.staff",

            /**
             * The permission to delete clinic/staff.
             */
            DELETE_CLINIC_STAFF: "delete.clinic.staff",

            /**
             * The permission to query clinic associate.
             */
            QUERY_CLINIC_ASSOCIATE: "query.clinic.associate",

            /**
             * The permission to create clinic associate.
             */
            CREATE_CLINIC_ASSOCIATE: "create.clinic.associate",

            /**
             * The permission to read clinic associate.
             */
            READ_CLINIC_ASSOCIATE: "read.clinic.associate",

            /**
             * The permission to update clinic associate.
             */
            UPDATE_CLINIC_ASSOCIATE: "update.clinic.associate",

            /**
             * The permission to delete clinic associate.
             */
            DELETE_CLINIC_ASSOCIATE: "delete.clinic.associate",

            /**
             * The permission to act as a clinic associate. Granted by home clinic.
             */
            ACT_AS_CLINIC_ASSOCIATE: "act-as.clinic-associate",

            /**
             * The permission to create clinic/patients.
             */
            CREATE_CLINIC_PATIENT: "create.clinic.patient",

            /**
             * The permission to read clinic/patients.
             */
            READ_CLINIC_PATIENT: "read.clinic.patient",

            /**
             * The permission to update clinic/patients.
             */
            UPDATE_CLINIC_PATIENT: "update.clinic.patient",

            /**
             * The permission to delete clinic/patients.
             */
            DELETE_CLINIC_PATIENT: "delete.clinic.patient",

            /**
             * The permission to transfer clinic/patients to another clinic in the clinic group.
             */
            TRANSFER_CLINIC_PATIENT: "transfer.clinic.patient",

            /**
             * The permission to create episodes of care.
             */
            CREATE_EPISODE: "create.clinic.episode",

            /**
             * The permission to read episodes of care.
             */
            READ_EPISODE: "read.clinic.episode",

            /**
             * The permission to update episodes of care.
             */
            UPDATE_EPISODE: "update.clinic.episode",

            /**
             * The permission to delete episodes of care.
             */
            DELETE_EPISODE: "delete.clinic.episode",

            /**
             * The permission to close episodes of care.
             */
            CLOSE_EPISODE: "close.clinic.episode",

            /**
             * The permission to create episode templates.
             */
            CREATE_EPISODE_TEMPLATE: "create.clinic.episode.template",

            /**
             * The permission to read episode templates.
             */
            READ_EPISODE_TEMPLATE: "read.clinic.episode.template",

            /**
             * The permission to update episode templates.
             */
            UPDATE_EPISODE_TEMPLATE: "update.clinic.episode.template",

            /**
             * The permission to delete episode templates.
             */
            DELETE_EPISODE_TEMPLATE: "delete.clinic.episode.template",

            /**
             * The permission to create prescriptions.
             */
            CREATE_PRESCRIPTION: "create.clinic.prescription",

            /**
             * The permission to read prescriptions.
             */
            READ_PRESCRIPTION: "read.clinic.prescription",

            /**
             * The permission to update prescriptions.
             */
            UPDATE_PRESCRIPTION: "update.clinic.prescription",

            /**
             * The permission to delete prescriptions.
             */
            DELETE_PRESCRIPTION: "delete.clinic.prescription",

            /**
             * The permission to create prescription exercises.
             */
            CREATE_PRESCRIPTION_EXERCISE: "create.clinic.prescription.exercise",

            /**
             * The permission to read prescription exercises.
             */
            READ_PRESCRIPTION_EXERCISE: "read.clinic.prescription.exercise",

            /**
             * The permission to update prescription exercises.
             */
            UPDATE_PRESCRIPTION_EXERCISE: "update.clinic.prescription.exercise",

            /**
             * The permission to delete prescription exercises.
             */
            DELETE_PRESCRIPTION_EXERCISE: "delete.clinic.prescription.exercise",

            /**
             * The permission to create episode resource materials.
             */
            CREATE_EPISODE_RESOURCE_MATERIAL: "create.episode.resource.material",

            /**
             * The permission to read episode resource materials.
             */
            READ_EPISODE_RESOURCE_MATERIAL: "read.episode.resource.material",

            /**
             * The permission to update episode resource materials.
             */
            UPDATE_EPISODE_RESOURCE_MATERIAL: "update.episode.resource.material",

            /**
             * The permission to delete episode resource materials.
             */
            DELETE_EPISODE_RESOURCE_MATERIAL: "delete.episode.resource.material",

            /**
             * The permission to read metrics.
             */
            READ_METRICS: "read.metrics",

            /**
             * The permission to create persons.
             */
            CREATE_PERSON: "create.person",

            /**
             * The permission to read persons.
             */
            READ_PERSON: "read.person",

            /**
             * The permission to update persons.
             */
            UPDATE_PERSON: "update.person",

            /**
             * The permission to delete persons.
             */
            DELETE_PERSON: "delete.person",

            /**
             * The permission to create In Hand Health staff.
             */
            CREATE_STAFF: "create.staff",

            /**
             * The permission to read In Hand Health staff.
             */
            READ_STAFF: "read.staff",

            /**
             * The permission to update In Hand Health staff.
             */
            UPDATE_STAFF: "update.staff",

            /**
             * The permission to delete In Hand Health staff.
             */
            DELETE_STAFF: "delete.staff",

            /**
             * The permission to update a person's password, without the old password, and without a reset token.
             */
            UPDATE_PERSON_PASSWORD: "update.person.password",

            /**
             * The permission to manage a person's devices for push notifications.
             */
            MANAGE_PERSON_DEVICES: "manage.person.devices",

            /**
             * The permission to create person alerts.
             */
            CREATE_PERSON_ALERT: "create.person-alert",

            /**
             * The permission to read person alerts.
             */
            READ_PERSON_ALERT: "read.person-alert",

            /**
             * The permission to update person alerts.
             */
            UPDATE_PERSON_ALERT: "update.person-alert",

            /**
             * The permission to delete person alerts.
             */
            DELETE_PERSON_ALERT: "delete.person-alert",

            /**
             * The permission to create addresses.
             */
            CREATE_ADDRESS: "create.address",

            /**
             * The permission to read addresses.
             */
            READ_ADDRESS: "read.address",

            /**
             * The permission to update addresses.
             */
            UPDATE_ADDRESS: "update.address",

            /**
             * The permission to delete addresses.
             */
            DELETE_ADDRESS: "delete.address",

            /**
             * The permission to create phones.
             */
            CREATE_PHONE: "create.phone",

            /**
             * The permission to read phones.
             */
            READ_PHONE: "read.phone",

            /**
             * The permission to update phones.
             */
            UPDATE_PHONE: "update.phone",

            /**
             * The permission to delete phones.
             */
            DELETE_PHONE: "delete.phone",

            /**
             * The permission to create emails.
             */
            CREATE_EMAIL: "create.email",

            /**
             * The permission to read emails.
             */
            READ_EMAIL: "read.email",

            /**
             * The permission to update emails.
             */
            UPDATE_EMAIL: "update.email",

            /**
             * The permission to delete emails.
             */
            DELETE_EMAIL: "delete.email",

            /**
             * The permission to read alerts themselves, not their deliveries.
             */
            READ_ALERT: "read.alert",

            /**
             * The permission to create an alert, which can cause deliveries to be created too.
             */
            CREATE_ALERT: "create.alert",

            /**
             * The permission to read alerts sent by another user : again, not deliveries).
             */
            READ_OTHER_ALERT: "read.other.alert",

            /**
             * The permission to get a session for a different caller. The caller can always get his own session.
             */
            READ_SESSION: "read.session",

            /**
             * The permission to delete a session : log out) for a different caller. The caller can always delete his own session.
             */
            DELETE_SESSION: "delete.session",

            /**
             * The permission to get exercise sets when not the patient. A patient caller can always get his own exercise sets.
             */
            READ_EXERCISE_SETS: "read.exercise-sets",

            /**
             * The permission to read therapy messages when not the patient or therapist.
             */
            READ_THERAPY_MESSAGE: "read.therapy-messages",

            /**
             * The permission to create therapy messages when not the patient or therapist.
             */
            CREATE_THERAPY_MESSAGE: "create.therapy-message",

            /**
             * The permission to update therapy messages when not the patient or therapist.
             */
            UPDATE_THERAPY_MESSAGE: "update.therapy-message",

            /**
             * The permission to delete therapy messages when not the patient or therapist.
             */
            DELETE_THERAPY_MESSAGE: "delete.therapy-message",

            /**
             * The permission to read therapy notes when not the patient or therapist.
             */
            READ_THERAPY_NOTE: "read.therapy-note",

            /**
             * The permission to create therapy notes when not the patient or therapist.
             */
            CREATE_THERAPY_NOTE: "create.therapy-note",

            /**
             * The permission to update therapy notes when not the patient or therapist.
             */
            UPDATE_THERAPY_NOTE: "update.therapy-note",

            /**
             * The permission to delete therapy notes when not the patient or therapist.
             */
            DELETE_THERAPY_NOTE: "delete.therapy-note",

            /**
             * The permission to get exercise sessions.
             */
            READ_WORKOUTS: "read.workouts",

            /**
             * The permission to create exercise sessions.
             */
            CREATE_WORKOUT: "create.workout",

            /**
             * The permission to create a payment token to initiate client gathering of payment information.
             */
            CREATE_PAYMENT_TOKEN: "create.payment.token",

            /**
             * The permission to read a payment method.
             */
            READ_PAYMENT_METHOD: "read.payment.method",

            /**
             * The permission to create a payment method.
             */
            CREATE_PAYMENT_METHOD: "create.payment.method",

            /**
             * The permission to update a payment method.
             */
            UPDATE_PAYMENT_METHOD: "update.payment.method",

            /**
             * The permission to delete a payment method.
             */
            DELETE_PAYMENT_METHOD: "delete.payment.method",

            /**
             * The permission to read sub-merchant accounts.
             */
            READ_SUB_MERCHANT_ACCOUNT: "read.sub-merchant-account",

            /**
             * The permission to create sub-merchant accounts.
             */
            CREATE_SUB_MERCHANT_ACCOUNT: "create.sub-merchant-account",

            /**
             * The permission to update sub-merchant accounts.
             */
            UPDATE_SUB_MERCHANT_ACCOUNT: "update.sub-merchant-account",

            /**
             * The permission to delete sub-merchant accounts.
             */
            DELETE_SUB_MERCHANT_ACCOUNT: "delete.sub-merchant-account",

            /**
             * The permission to get patient subscription settings.
             */
            READ_PATIENT_SUBSCRIPTION_SETTINGS: "read.patient-subscription-settings",

            /**
             * The permission to update patient subscription settings.
             */
            UPDATE_PATIENT_SUBSCRIPTION_SETTINGS: "update.patient-subscription-settings",

            /**
             * The permission to get patient subscription settings for a clinic.
             */
            READ_PATIENT_SUBSCRIPTION_CLINIC_SETTINGS: "read.patient-subscription-clinic-settings",

            /**
             * The permission to update patient subscription settings for a clinic.
             */
            UPDATE_PATIENT_SUBSCRIPTION_CLINIC_SETTINGS: "update.patient-subscription-clinic-settings",

            /**
             * The permission to read clinic plan subscription settings.
             */
            READ_CLINIC_PLAN_SUBSCRIPTION_SETTINGS: "read.clinic-plan-subscription-settings",

            /**
             * The permission to update clinic plan subscription settings.
             */
            UPDATE_CLINIC_PLAN_SUBSCRIPTION_SETTINGS: "update.clinic-plan-subscription-settings",

            /**
             * The permission to get patient subscription products.
             */
            READ_PATIENT_SUBSCRIPTION_PRODUCTS: "read.patient-subscription-product",

            /**
             * The permission to create patient subscription products.
             */
            CREATE_PATIENT_SUBSCRIPTION_PRODUCTS: "create.patient-subscription-product",

            /**
             * The permission to update patient subscription products.
             */
            UPDATE_PATIENT_SUBSCRIPTION_PRODUCTS: "update.patient-subscription-product",

            /**
             * The permission to delete patient subscription products.
             */
            DELETE_PATIENT_SUBSCRIPTION_PRODUCTS: "delete.patient-subscription-product",

            /**
             * The permission to get patient subscription.
             */
            READ_PATIENT_SUBSCRIPTION: "read.patient-subscription",

            /**
             * The permission to create patient subscription.
             */
            CREATE_PATIENT_SUBSCRIPTION: "create.patient-subscription",

            /**
             * The permission to update patient subscription.
             */
            UPDATE_PATIENT_SUBSCRIPTION: "update.patient-subscription",

            /**
             * The permission to get clinic plan subscription usage.
             */
            READ_CLINIC_PLAN_USAGE: "read.clinic-plan-usage",

            /**
             * The permission to get clinic plan subscription.
             */
            READ_CLINIC_PLAN_SUBSCRIPTION: "read.clinic-plan-subscription",

            /**
             * The permission to create clinic plan subscription.
             */
            CREATE_CLINIC_PLAN_SUBSCRIPTION: "create.clinic-plan-subscription",

            /**
             * The permission to update clinic plan subscription.
             */
            UPDATE_CLINIC_PLAN_SUBSCRIPTION: "update.clinic-plan-subscription",

            /**
             * The permission to create clinic patient subscription products.
             */
            CREATE_CLINIC_PATIENT_SUBSCRIPTION_PRODUCTS: "create.clinic-patient-subscription-product",

            /**
             * The permission to read clinic patient subscription products.
             */
            READ_CLINIC_PATIENT_SUBSCRIPTION_PRODUCTS: "read.clinic-patient-subscription-product",

            /**
             * The permission to update a single clinic patient subscription products.
             */
            UPDATE_CLINIC_PATIENT_SUBSCRIPTION_PRODUCTS: "update.clinic-patient-subscription-product",

            /**
             * The permission to delete clinic patient subscription products.
             */
            DELETE_CLINIC_PATIENT_SUBSCRIPTION_PRODUCTS: "delete.clinic-patient-subscription-product",

            /**
             * The permission to create clinic plan subscription products.
             */
            CREATE_CLINIC_PLAN_SUBSCRIPTION_PRODUCTS: "create.clinic-plan-subscription-product",

            /**
             * The permission to read clinic plan subscription products.
             */
            READ_CLINIC_PLAN_SUBSCRIPTION_PRODUCTS: "read.clinic-plan-subscription-product",

            /**
             * The permission to update a single clinic plan subscription products.
             */
            UPDATE_CLINIC_PLAN_SUBSCRIPTION_PRODUCTS: "update.clinic-plan-subscription-product",

            /**
             * The permission to delete clinic plan subscription products.
             */
            DELETE_CLINIC_PLAN_SUBSCRIPTION_PRODUCTS: "delete.clinic-plan-subscription-product",

            /**
             * The permission to create managed clinic plans.
             */
            CREATE_MANAGED_CLINIC_PLANS: "create.managed-clinic-plan",

            /**
             * The permission to read managed clinic plans.
             */
            READ_MANAGED_CLINIC_PLANS: "read.managed-clinic-plan",

            /**
             * The permission to update a single managed clinic plan.
             */
            UPDATE_MANAGED_CLINIC_PLANS: "update.managed-clinic-plan",

            /**
             * The permission to delete managed clinic plans.
             */
            DELETE_MANAGED_CLINIC_PLANS: "delete.managed-clinic-plan",

            /**
             * The permission to read subscription term.
             */
            READ_SUBSCRIPTION_TERM: "read.subscription-term",

            /**
             * The permission to create campaigns.
             */
            CREATE_CAMPAIGN: "create.clinic.campaign",

            /**
             * The permission to read campaigns.
             */
            READ_CAMPAIGN: "read.clinic.campaign",

            /**
             * The permission to update campaigns.
             */
            UPDATE_CAMPAIGN: "update.clinic.campaign",

            /**
             * The permission to delete campaigns.
             */
            DELETE_CAMPAIGN: "delete.clinic.campaign"
        });
})();