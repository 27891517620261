(function () {
    'use strict';

    /**
     * This directive blocks touchend events from propagating beyond the modal form
     *
     * This solution was found here: https://github.com/angular-ui/bootstrap/issues/2017
     *
     * The interpretation is that it is likely an angular-touch bug related to the
     * interactions between ng-click and angular-touch when there are forms inside of modals..
     *
     * Following the comments on the above issue will result in other suggested resolutions, but
     * this implementation is a straightforward solution.
     */
    angular
        .module('ihh-common-web')
        .directive('stopEvent', function () {
            return {
                restrict: 'A',
                link: function (scope, element, attr) {
                    element.on(attr.stopEvent, function (e) {
                        e.stopPropagation();
                    });
                }
            };
        });
})();