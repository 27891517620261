(function () {
    'use strict';

    angular
        .module('ihh-common-web')
        .service('EpisodeTemplateService', EpisodeTemplateService);

    EpisodeTemplateService.$inject = ['ObserverService', 'EpisodeTemplateConstants'];

    function EpisodeTemplateService (ObserverService, EpisodeTemplateConstants) {
        var service = {
            displayBusyIndicator: displayBusyIndicator,
            hideBusyIndicator: hideBusyIndicator
        };

        return service;

        function displayBusyIndicator () {
            ObserverService.notifyObservers(EpisodeTemplateConstants.observers.form.displayBusyIndicator, {});
        }

        function hideBusyIndicator () {
            ObserverService.notifyObservers(EpisodeTemplateConstants.observers.form.hideBusyIndicator, {});
        }
    }
})();