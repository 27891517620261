(function () {
    'use strict';

    angular
        .module('ihh-common-web')
        .service('ProfileService', ProfileService);

    ProfileService.$inject = ['$q', 'RestResources', 'moment', 'ProfileConstants', 'appConfig', 'personUtilities'];

    function ProfileService ($q, RestResources, moment, ProfileConstants, appConfig, personUtilities) {
        var self = this;

        var service = {
            getMyAlerts: getMyAlerts,
            getStaffId: getStaffId
        };

        self.alerts = {
            alertList: [],
            lastRetrieved: null
        };

        return service;

        function getStaffId (self, user) {
            var staffId = null;

            if (isTherapistApp() && user.clinic) {
                staffId = personUtilities.getClinicProfessionalId(self, user.clinic);
            }
            else if (isOpsApp()) {
                staffId = self.ihhProfessional.id;
            }

            return staffId;
        }

        function getClinicId (user) {
            var clinicId = null;

            if (isTherapistApp() && user.clinic) {
                clinicId = user.clinic.id;
            }

            return clinicId;
        }

        /**
         * getMyAlerts is responsible for querying for alerts, or returning the
         * existing alerts object.
         *
         * @param {string} staffId
         * @param {boolean} overrideTimer override the timer check and query immediately
         * @param {object} user
         */
        function getMyAlerts (staffId, overrideTimer, user) {
            var deferred = $q.defer();

            if (overrideTimer || queryExpired()) {
                queryAlerts(staffId, user).then(function (result) {
                    deferred.resolve(processQueryResult(result));
                }, function (err) {
                    deferred.reject(err);
                });
            }
            else {
                deferred.resolve(self.alerts);
            }

            return deferred.promise;
        }

        /**
         * Queries the resource for alerts of the logged in user
         *
         * @param {string} staffId
         * @param {object} user
         * @returns {object} promise
         */
        function queryAlerts (staffId, user) {
            var deferred = $q.defer();
            var resource = RestResources.getAlertReceiverResource(staffId, getClinicId(user));

            resource.get({
                staffId: staffId,
                read: false
            }, function (result) {
                deferred.resolve(result);
            }, function (err) {
                deferred.reject(err);
            });

            return deferred.promise;
        }

        function processQueryResult (result) {
            var length = result.length;
            var count = 0;
            var returnValue = [];

            while (count < length) {
                returnValue.push(result[count]);
                count++;
            }

            self.alerts = {
                alertList: returnValue,
                lastRetrieved: getCurrentTime()
            };

            return self.alerts;
        }

        function queryExpired () {
            var lastRetrieved = self.alerts.lastRetrieved;
            var timeDiff = 0;

            if (lastRetrieved !== null) {
                timeDiff = moment(getCurrentTime()).diff(moment(lastRetrieved), 'seconds');
            }

            return timeDiff >= ProfileConstants.pollingFrequency.seconds;
        }

        function getCurrentTime () {
            return moment.utc();
        }

        function isTherapistApp() {
            return appConfig.app === 'therapist';
        }

        function isOpsApp() {
            return appConfig.app === 'admin';
        }
    }
})();