(function () {
    'use strict';

    /**
     * @description
     *  formConstants are used as template strings for form alert banners
     */
    angular
        .module('ihh-common-web')
        .constant('formConstants', {
            protocols: {
                template: {
                    prefix: 'rxtpl',
                    text: 'Template #{0} {1}'
                },
                exercise: {
                    prefix: 'rxextpl',
                    text: 'Template #{0} Exercise #{1} {2}'
                }
            },
            exerciseTemplates: {
                exercise: {
                    prefix: 'extpl',
                    text: 'Exercise #{0} {1}'
                }
            },
            exercises: {
                exercise: {
                    prefix: 'ex',
                    text: '{0}'
                }
            }
        });
})();