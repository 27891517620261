(function () {
    'use strict';

    angular
        .module('ihh-common-web')
        .constant('CommonWebClinicLicenseConstants', {
            autorenewLabel: {
                trial: 'Continue After Trial',
                regular: 'Auto Renew'
            },
            dateLabel: {
                trialOn: 'Trial End Date',
                trialOff: 'Trial End Date',
                regularOn: 'Renewal Date',
                regularOff: 'Expiration Date'
            },
            paymentPeriod: {
                month: {
                    key: 'monthly',
                    paymentPeriodKey: 'month',
                    paymentPeriodLabel: 'Monthly'
                },
                year: {
                    key: 'annually',
                    paymentPeriodKey: 'year',
                    paymentPeriodLabel: 'Annually'
                }
            }
        });
})();