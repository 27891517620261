(function () {
    'use strict';

    angular
        .module('ihh-common-web')
        .directive('ihhClinicPricingView', ihhClinicPricingView);

    ihhClinicPricingView.$inject = ['CommonPricingPlanConstants'];

    function ihhClinicPricingView(CommonPricingPlanConstants) {
        return {
            restrict: 'AE',
            scope: {
                /**
                 * The scope variable used to display a price
                 * @ngdoc property
                 * @name price
                 * @memberOf ihh-common-web.ihh-clinic-pricing-view
                 */
                price: '=',

                /**
                 * The scope variable used to display the "Paid" text ex: "Paid Monthly"
                 * @ngdoc property
                 * @name priceNotSetText
                 * @memberOf ihh-common-web.ihh-clinic-pricing-view
                 */
                termPeriod: '@',

                /**
                 * The scope variable used to display the "Not Set" text ex: "Monthly Price Not Set"
                 * @ngdoc property
                 * @name paidText
                 * @memberOf ihh-common-web.ihh-clinic-pricing-view
                 */
                paymentPeriod: '@'
            },
            templateUrl: 'clinic-plan/clinic-pricing-view.tpl.html',
            link: function (scope) {
                if (scope.termPeriod && scope.paymentPeriod) {
                    var constantVal = CommonPricingPlanConstants.termPeriod[scope.termPeriod].paymentPeriod[scope.paymentPeriod];
                    scope.priceLabel = constantVal.priceLabel;
                    scope.paidLabel = constantVal.paid;
                }
            }
        };
    }
})();