(function () {
    'use strict';

    angular
        .module('ihh-common-web')
        .constant('alertConstants', {
            levels: {
                success: {
                    key: 'success',
                    label: 'Success!',
                    cssClass: 'alert-success'
                },
                info: {
                    key: 'info',
                    label: 'Info!',
                    cssClass: 'alert-info'
                },
                warning: {
                    key: 'warning',
                    label: 'Warning!',
                    cssClass: 'alert-warning'
                },
                danger: {
                    key: 'danger',
                    label: 'Danger!',
                    cssClass: 'alert-danger'
                }
                /*,
                newLevel: {
                    key: 4, // no implied order. must be unique
                    label: '',
                    ccsClass: ''
                }*/
            },
            types: {
                form: {
                    key: 'form',
                    text: 'The following fields were invalid or incomplete: {0}.'
                }
            }
        });
})();