(function () {
    'use strict';

    angular
        .module('ihh-common-web')
        .constant('PaymentMethodEntryConstants', {
            notification: {
                onPaymentMethodReceived: 'paymentMethod.notification.onPaymentMethodReceived',
                setPaymentMethod: 'paymentMethod.notification.setPaymentMethod'
            },
            formControls: {
                closeForm: 'PaymentMethodEntryConstants.formControls.closeForm'
            }
        });
})();