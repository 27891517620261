(function () {
    'use strict';

    angular
        .module('ihh-common-web')
        .constant('EpisodeTemplateConstants', {
            observers : {
                form : {
                    displayBusyIndicator: 'EpisodeTemplateConstants.observers.form.displayBusyIndicator',
                    hideBusyIndicator: 'EpisodeTemplateConstants.observers.form.hideBusyIndicator'
                }
            }
        });
})();