(function () {
    'use strict';

    angular
        .module('ihh-common-web')
        .directive('ihhThumbnailRow', ihhThumbnailRow);

    ihhThumbnailRow.$inject = ['$timeout', '$window', 'RestResources', 'Utilities'];

    function ihhThumbnailRow($timeout, $window, RestResources, Utilities) {
        return {
            restrict: 'A',
            scope: {
                exerciseTemplates: '=',
                config: '='
            },
            templateUrl: 'item-chooser/thumbnail-row.tpl.html',
            link: function(scope, element) {
                scope.thumbnailLimit = 1;

                if (angular.isArray(scope.exerciseTemplates)) {
                    for (var i = 0; i < scope.exerciseTemplates.length; i++) {
                        if (Utilities.isDefinedAndNotNull(scope.exerciseTemplates[i].exercise)) {
                            if (!Utilities.isDefinedAndNotNull(scope.exerciseTemplates[i].exercise.thumbnailUrl)) {
                                scope.exerciseTemplates[i].exercise.thumbnailUrl = RestResources.getDefaultExerciseThumbnailUrl();
                            }
                        }
                    }
                }

                /**
                 *
                 * @param {type} tabIndex index of the active tab
                 * @returns {undefined} none
                 */
                var updateThumbnailLimit = function() {
                    $timeout(function() { //ensure digest cycle is complete
                        var offsetWidth = element.context.offsetWidth;
                        if (offsetWidth > 0) {
                            var thumbnailSize = angular.element(document.querySelectorAll('.exercise-thumbnail-image')).innerWidth();

                            if (thumbnailSize === null) {
                                thumbnailSize = 48;
                            }

                            scope.thumbnailLimit = parseInt((offsetWidth - thumbnailSize) / thumbnailSize, 10);
                            if (scope.exerciseTemplates && scope.exerciseTemplates.length > scope.thumbnailLimit && scope.thumbnailLimit > 0) {
                                scope.thumbnailLimit--;
                            }
                        }
                    });
                };

                scope.$on('tabStateUpdated', function() {
                    updateThumbnailLimit();
                });

                angular.element($window).on('resize', function() {
                    updateThumbnailLimit();
                });

                updateThumbnailLimit();
            }
        };
    }
})();