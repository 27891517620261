(function () {
    'use strict';

    angular
        .module('ihh-common-web')
        .constant('modalConstants', {
            headers: {
                ATTENTION: 'Attention!'
            },
            controls: {
                OK: 'OK',
                SAVE_PUBLISHED: 'Save as Published',
                SAVE_DRAFT: 'Save as Draft'
            },
            exerciseTemplate: {
                CANNOT_PUBLISH: 'Cannot Publish Template',
                PUBLISH_REQUIREMENT_TEXT: 'The template contains exercises not as widely published as the proposed publishing level. Remove them or publish them more widely to publish this template.'
            },
            protocol: {
                CANNOT_PUBLISH: 'Cannot Publish Protocol',
                PUBLISH_REQUIREMENT_TEXT: 'The protocol contains exercises not as widely published as the proposed publishing level. Remove them or publish them more widely to publish this protocol.'
            },
            exercise: {
                CANNOT_PUBLISH: 'Cannot Publish Exercise',
                PUBLISH_REQUIREMENT_TEXT: 'The exercise must have a name, description, thumbnail, audio announcement, at least one still image, and at least one video before it may be published.',
                REPORT_ONLY_PUBLISH_REQUIREMENT_TEXT: 'The exercise must have a name, description, thumbnail, and at least one still image or one video before it may be published.',
                MEETS_REQUIREMENTS: 'This exercise meets the requirements to be published, would you like to Publish this exercise? If saved as Draft, it will be available in your My Saved library, but will not be available to use in a prescription or protocol until it is published.'
            },
            unsavedChanges: {
                TEXT: 'Please finish what you are working on before continuing.',
                LABEL: 'Okay',
                HEADER: 'Warning'
            }
        });
})();
