(function () {
    'use strict';

    angular
        .module('ihh-common-web')
        .factory('ClinicLicenseFactory', ClinicLicenseFactory);

    ClinicLicenseFactory.$inject = ['CommonWebClinicLicenseConstants', '_'];

    /*
     * Common functions on clinic plans and subscriptions.
     */
    function ClinicLicenseFactory(CommonWebClinicLicenseConstants, _) {
        var month = 'month';

        var service = {
            getDateLabel: getDateLabel,
            getAutorenewLabel: getAutorenewLabel,
            getPrice: getPrice,
            getPlanPrice: getPlanPrice,
            getPricePerUser: getPricePerUser,
            getOneTimeCharge: getOneTimeCharge,
            getOneTimeChargeMemo: getOneTimeChargeMemo
        };

        return service;

        /**
         * This function returns a sensible label for the subscription end date, based on several factors.
         *
         * @param {object} subscription - the provided subscription object
         */
        function getDateLabel (subscription) {
            var dateLabel;

            if (subscription.initial) {
                if (subscription.autorenew) {
                    dateLabel = CommonWebClinicLicenseConstants.dateLabel.trialOn;
                }
                else {
                    dateLabel = CommonWebClinicLicenseConstants.dateLabel.trialOff;
                }
            }
            else if (subscription.autorenew) {
                dateLabel = CommonWebClinicLicenseConstants.dateLabel.regularOn;
            }
            else {
                dateLabel = CommonWebClinicLicenseConstants.dateLabel.regularOff;
            }

            return dateLabel;
        }

        function getAutorenewLabel(subscription) {
            var label;

            if (subscription.initial) {
                label = CommonWebClinicLicenseConstants.autorenewLabel.trial;
            }
            else {
                label = CommonWebClinicLicenseConstants.autorenewLabel.regular;
            }

            return label;
        }

        /**
         *  This functions returns the total clinic subscription price based on the plan price,
         *  the additional user price, and the number of additional users.
         *
         *  @param {object} product the product of the subscription
         *  @param {object} paymentPeriod the payment period of the subscription
         *  @param {object} additionalUsers the number of additional users
         *
         *  @returns {number} price - the calculated price of a subscription
         */
        function getPrice (product, paymentPeriod, additionalUsers) {
            var planPrice = getPlanPrice(product, paymentPeriod);
            var pricePerUser = getPricePerUser(product, paymentPeriod);
            var price = planPrice + (pricePerUser * additionalUsers);

            return price;
        }

        /**
         *  This functions returns the plan price for the given product and payment period.
         *
         *  @param {object} product the product
         *  @param {object} paymentPeriod the payment period
         *
         *  @returns {number} the price per user
         */
        function getPlanPrice (product, paymentPeriod) {
            return paymentPeriod === month ? product.monthlyPrice : product.annualPrice;
        }

        /**
         *  This functions returns the price per user for the given product and payment period.
         *
         *  @param {object} product the product
         *  @param {object} paymentPeriod the payment period
         *
         *  @returns {number} the price per user
         */
        function getPricePerUser (product, paymentPeriod) {
            return paymentPeriod === month ? product.monthlyPricePerUser : product.annualPricePerUser;
        }

        /**
         * Returns any one time charge that is applicable for the subscription renewal.
         *
         * @param {type} product the renewal product
         * @param {type} subscription the current subscription
         * @returns {Number} the one time charge
         */
        function getOneTimeCharge(product, subscription) {
            return subscription.initial ? product.oneTimeCharge : 0.0;
        }

        /**
         * Returns any one time charge memo that is applicable for the subscription renewal.
         *
         * @param {type} product the renewal product
         * @param {type} subscription the current subscription
         * @returns {Number} the one time charge
         */
        function getOneTimeChargeMemo(product, subscription) {
            return subscription.initial ? product.oneTimeChargeMemo : null;
        }
    }
})();