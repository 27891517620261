(function () {
    /*
     * Copied from https://gist.github.com/hahn-kev/b3e74a07b53c26ddee1b
     * Modifed by JSBeautify and to meet JSLint standards. Also edited to allow an undefined or null mask to bypass filtering.
     * NB: this does not support custom placeholder chars.
     */

    'use strict';

    angular
        .module('ihh-filters')
        .filter('maskfilter', maskFilter);

    function maskFilter () {
        var cache = {};
        var maskDefinitions = {
            '9': /\d/,
            'A': /[a-zA-Z]/,
            '*': /[a-zA-Z0-9]/
        };

        function getPlaceholderChar( /*i*/ ) {
            return '_';
        }

        function processRawMask(mask) {
            if (cache[mask]) {
                return cache[mask];
            }
            if (angular.isUndefined(mask) || mask === null) {
                return mask;
            }

            var characterCount = 0;

            var maskCaretMap = [];
            var maskPatterns = [];
            var maskPlaceholder = '';
            if (angular.isString(mask)) {


                var numberOfOptionalCharacters = 0,
                    splitMask = mask.split('');

                angular.forEach(splitMask, function(chr, i) {
                    if (maskDefinitions[chr]) {

                        maskCaretMap.push(characterCount);

                        maskPlaceholder += getPlaceholderChar(i - numberOfOptionalCharacters);
                        maskPatterns.push(maskDefinitions[chr]);

                        characterCount++;
                    }
                    else if (chr === '?') {
                        numberOfOptionalCharacters++;
                    }
                    else {
                        maskPlaceholder += chr;
                        characterCount++;
                    }
                });
            }
            // Caret position immediately following last position is valid.
            maskCaretMap.push(maskCaretMap.slice().pop() + 1);
            cache[mask] = {
                maskCaretMap: maskCaretMap,
                maskPlaceholder: maskPlaceholder
            };
            return cache[mask];
        }

        function maskValue(unmaskedValue, maskDef) {
            if (angular.isUndefined(maskDef) || maskDef === null) {
                return unmaskedValue;
            }

            unmaskedValue = unmaskedValue || '';
            var valueMasked = '',
                maskCaretMapCopy = maskDef.maskCaretMap.slice();

            angular.forEach(maskDef.maskPlaceholder.split(''), function(chr, i) {
                if (unmaskedValue.length && i === maskCaretMapCopy[0]) {
                    valueMasked += unmaskedValue.charAt(0) || '_';
                    unmaskedValue = unmaskedValue.substr(1);
                    maskCaretMapCopy.shift();
                }
                else {
                    valueMasked += chr;
                }
            });
            return valueMasked;

        }

        return function(value, mask) {
            var maskDef = processRawMask(mask);
            var maskedValue = maskValue(value, maskDef);
            return maskedValue;
        };
    }
})();