(function () {
    'use strict';

    angular
        .module('ihh-common-web')
        .directive('ihhAlert', ihhAlert);

    ihhAlert.$inject = ['$rootScope', '$timeout', 'alertConstants'];

    function ihhAlert($rootScope, $timeout, alertConstants) {
        return {
            restrict: 'E',
            scope: {},
            transclude: true,
            templateUrl: 'alert/alert.tpl.html',
            link: function (scope/*, element, attrs*/ ) {
                var callerProvidedName = ''; // used to determine which form prompted display
                var alertLevel = alertConstants.levels;
                var alertTypes = alertConstants.types;
                var isHidden = true;

                scope.cssClass = '';

                scope.alertMessage = {
                    title: '',
                    description: ''
                };

                scope.hideAlertBanner = function () {
                    hideAlertBanner(undefined, true);
                };

                // begin function declarations
                /** This function updates the cssClass
                 *
                 * @param {String} cssClass the class to update with
                 * @returns {undefined}
                 */
                function updateCssClass(cssClass) {
                    scope.cssClass = cssClass;
                }

                /**
                 * This function shows the alert banner if it is not currently
                 * displayed. This function also updates the alert class to
                 * the appropriate bootstrap class. warning/info/success/danger.
                 *
                 * @param {String} alertClass the alert class to be applied
                 *      to the alert banner
                 * @param {String} callerName the string representation of the calling
                 *      element.
                 * @returns {undefined}
                 */
                function showAlertBanner(alertClass, callerName) {
                    // update the alert class
                    updateCssClass(alertClass);
                    callerProvidedName = callerName;

                    if (isHidden) {
                        isHidden = false;
                    } // else the banner is already displayed
                }

                /**
                 * This function hides the alert banner if it is currently
                 * displayed. Will not hide the banner if the callerName
                 * does not match the directive copy of callerName
                 *
                 * @param {String} callerName uniqueID of element prompting
                 *      this banner to be hidden
                 * @param {Boolean} override used to hideAlertBanner
                 *      regardless of the callerName
                 * @returns {undefined}
                 */
                function hideAlertBanner(callerName, override) {
                    if ((!scope.isHidden && callerName === callerProvidedName) || override) {
                        isHidden = true;
                        updateCssClass('hidden');
                    } // else the banner is already hidden
                }

                // Internal events
                /**
                 * Listens for the missingItemsUpdated $broadcast message and
                 * updates the alert banner as appropriate. To clear the message,
                 * an empty array may be supplied
                 *
                 * @param {event}
                 * @param {args}
                 *  [
                 *      {alertLevel|Number}
                 *      {messageType|Number}
                 *      {message|String}
                 *      {callerName|String}
                 *  ]
                 * @returns {undefined}
                 */
                scope.$on('updateAlertBanner', function (event, args) {
                    var alertTitle = '';
                    var alertDescription = '';
                    var validMessage = true;
                    var alertClass;

                    if (angular.isString(args.key) && angular.isString(args.messageType) && angular.isString(args.message) && angular.isString(args.callerName)) {

                        // get the appropriate alert type based on the provided level
                        if (angular.isDefined(alertLevel[args.key])) {
                            alertTitle = alertLevel[args.key].label;
                            alertClass = alertLevel[args.key].cssClass;
                        }
                        else {
                            validMessage = false;
                        }

                        // get the appropriate alert message text based on the message type key
                        // interpolate the args.message
                        if (angular.isDefined(alertTypes[args.messageType])) {
                            switch (args.messageType) {
                            case alertTypes.form.key:
                                alertDescription = alertTypes.form.text.replace('{0}', args.message);
                                break;
                            default:
                                validMessage = false; // couldn't find the message type
                                break;
                            }
                        }

                        if (validMessage) {
                            $timeout(function () {
                                scope.alertMessage.title = alertTitle;
                                scope.alertMessage.description = alertDescription;
                            });

                            showAlertBanner(alertClass, args.callerName);
                        }
                        else {
                            //something went wrong (likely invalid alertLevel/alertType supplied)
                            hideAlertBanner(undefined, true); // fail gracefully
                        }
                    }
                    else { // we got at least one bad param
                        hideAlertBanner(undefined, true);
                    }
                });

                /**
                 * Listens for the hideAlertMessage $broadcast message.
                 * When it fires, the alert message will be hidden.
                 */
                scope.$on('hideAlertBanner', function (event, args) {
                    if (angular.isDefined(args) && angular.isDefined(args.callerName)) {
                        hideAlertBanner(args.callerName, false);
                    }
                    else {
                        hideAlertBanner(undefined, true);
                    }
                });

                // Angular events
                /**
                 * Listens for the $routeChangeStart event. When it fires,
                 * the alert message will be hidden.
                 */
                $rootScope.$on('$routeChangeStart', function () {
                    $timeout(function () {
                        if (!scope.isHidden && angular.element('.modal').length === 0) {
                            hideAlertBanner(undefined, true);
                        } // else the banner is already hidden
                    }, 50);
                });

                /**
                 * Listens for the $routeChangeSuccess event. When it fires,
                 * the alert message will be hidden.
                 *
                 * Why is this used? On pages with modals, we need an easy way
                 * to hide the alert banner when the user clicks on "continue"
                 * and proceeds to a different page. If the user clicks "cancel"
                 * the routeChange is not considered a success, and we do not
                 * want to hide the alert message.
                 */
                $rootScope.$on('$routeChangeSuccess', function () {
                    hideAlertBanner(undefined, true);
                });
            }
        };
    }
})();