(function() {
    'use strict';

    angular
        .module('ihh-common-web')
        .constant('Roles', {
            'ihh': [{
                label: 'Administrator',
                value: 'admin'
            }, {
                label: 'Content Manager',
                value: 'content'
            }, {
                label: 'Sales Manager',
                value: 'sales'
            }, {
                label: 'Accounting Manager',
                value: 'accounting'
            }, {
                label: 'Log Reader',
                value: 'log-reader'
            }],
            'clinic': [{
                label: 'Clinic Administrator',
                value: 'admin'
            }, {
                label: 'Patient Administrator',
                value: 'patient'
            }, {
                label: 'Clinician',
                value: 'therapist'
            }, {
                label: 'Associate',
                value: 'associate'
            }]
        });

    angular
        .module('ihh-common-web')
        .constant('RoleTypes', {
            'IHH': 'ihh',
            'CLINIC': 'clinic'
        });

})();