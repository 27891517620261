(function () {
    'use strict';

    /**
     * The unlimitedDisplay Filter allows the display of a number to be modified if
     * it exceeds one million.
     *
     * @memberof ihh-filters
     * @ngdoc filter
     * @name unlimitedDisplay
     * @description
     *  Returns an alternative display of a number to be modified if it exceeds one million.
     */
    angular
        .module('ihh-filters')
        .filter('unlimitedDisplay', unlimitedDisplay);

    function unlimitedDisplay() {
        /**
         * @func unlimitedDisplayFilter
         * @memberOf unlimitedDisplay
         * @description
         *  Create the return function that modifies the number display if it exceeds a maximum
         * @param {Number} input the value that is passed into the filter
         */
        return function (input) {
            return angular.isNumber(input) ? (input >= 1000000 ? 'Unlimited' : input) : input;
        };
    }
})();

